import { API_URL } from "./api";

// src/api.js
export const bookTrial = async (bookingData) => {
  try {
    const response = await fetch(`${API_URL}/api/demo/addDemobook`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bookingData),
    });

    // Log full response details for debugging
    console.log('Response:', response);

    if (!response.ok) {
      const errorText = await response.text(); // Get error details from the response body
      console.error('Error response body:', errorText);
      throw new Error(`Failed to book trial: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error occurred:', error);
    throw error;
  }
};
