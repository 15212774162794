import React from 'react';
import { Box, Typography, Container, Avatar,useMediaQuery ,useTheme} from '@mui/material';
import { CheckCircle, AssignmentInd, EventAvailable, DoneAll, Face } from '@mui/icons-material'; // Import MUI icons
import oratricslogo from '../assets/oratricslogo.png'; // Adjust the path as needed

function Step5() {
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const steps = [
    { label: 'Trial Class Accepted', icon: <CheckCircle />, color: '#4caf50', completed: true },
    { label: 'Teacher Assigned', icon: <AssignmentInd />, color: '#4caf50', completed: true },
    { label: 'Trial Confirmed', icon: <EventAvailable />, color: '#9e9e9e', completed: false },
    { label: 'Trial Completed', icon: <DoneAll />, color: '#9e9e9e', completed: false },
    { label: 'Counselling Completed', icon: <Face />, color: '#9e9e9e', completed: false },
  ];

  return (
    <Container
   >
          <Box sx={{ textAlign: 'center', px: 2, py: isMobile ? 2 : 4 }}>
        
        {/* <img width="50%" maxWidth={100} height="auto" src={oratricslogo} alt="Oratrics" /> */}
        <Typography variant="h5" mt={2} fontWeight="bold" color="#050a30">
          Congratulations! Your Slot Is Booked.
        </Typography>
      </Box>

      <Box textAlign="center" mb={4} bgcolor="#8b0000ec" p={2} borderRadius={3} display="inline-block">
        <Typography variant="h6" fontWeight="bold" color="white">
          {/* Sat, 10 Aug */}
        </Typography>
        <Typography variant="h4" color="#fff">
          {/* 05:00 PM */}
        </Typography>
        <Typography variant="body1" mt={1} color="white">
          A Platinum rated teacher will reach out to you shortly.
        </Typography>
      </Box>

      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }} // Vertical on small screens, horizontal on medium and up
        alignItems="center"
        justifyContent="center"
        mt={5}
        position="relative"
        width="100%"
      >
        {steps.map((step, index) => (
          <Box
            key={index}
            display="flex"
            flexDirection="column"
            alignItems="center"
            position="relative"
            sx={{ 
              mx: { xs: 1, md: 2 },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mb:isMobile?2:4,  // Margin at bottom for circles
            }}
          >
            <Avatar
              sx={{ 
                bgcolor: step.completed ? step.color : '#e0e0e0', 
                width: isMobile?30:56, 
                height: isMobile?30:56, 
                mb: 1 // Margin bottom for space between circle and text
              }}
            >
              {step.icon}
            </Avatar>
            <Typography 
              variant="body2" 
              color={step.completed ? 'text.primary' : '#9e9e9e'} 
              sx={{ 
                whiteSpace: 'nowrap', 
                textAlign: 'center',
                mb: 2 // Space between text and connector line
              }}
            >
              {step.label}
            </Typography>
            {index < steps.length - 1 && (
              <Box
                position="absolute"
                sx={{
                  width: isMobile?"1px":"2px", // Width for horizontal line
                  height: { xs: '40px', md: '3px' }, // Height for vertical line
                  bgcolor: steps[index + 1].completed ? '#4caf50' : '#e0e0e0',
                  top: { xs: 'calc(100% + 8px)', md: '50%' }, // Position line for mobile and desktop
                  left: { xs: '50%', md: `calc(100% / ${steps.length} * ${index + 1})` }, // Position line for mobile and desktop
                  transform: 'translateX(-50%) translateY(-50%)' // Center line properly
                }}
              />
            )}
          </Box>
        ))}
      </Box>
    </Container>
  );
}

export default Step5;
