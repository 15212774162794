import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  IconButton,
  AppBar,
  Tabs,
  Tab,
  useMediaQuery,
  useTheme,
  Snackbar,
  Alert as MuiAlert, 
  Toolbar,  Link,Menu,Tooltip, 
  CircularProgress

} from '@mui/material';
import ArrowForward from '@mui/icons-material/ArrowForward'; // Import your desired icon
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TimezoneSelect from 'react-timezone-select';
import { bookTrial } from './config/apiService'; // Import the API helper function
import portrait from './assets/portrait.png'; // Adjust path as needed
import logo from './assets/oratricslogo.png';
import Step5 from './page/StepFive';
import { countryCodes } from './component/CountryCode';
import MenuIcon from '@mui/icons-material/Menu';
import Info from '@mui/icons-material/Info'; // Import the Info icon or your desired icon

const App = () => {
  const [step, setStep] = useState(1);
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [childName, setChildName] = useState('');

  const [selectedDate, setSelectedDate] = useState(0);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [selectedTimeZone, setSelectedTimeZone] = useState('UTC');
  const [timeSlots, setTimeSlots] = useState([]);
  const [countryCode, setCountryCode] = useState('+1');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const today = new Date();
  const daysOfWeek = generateDaysOfWeek();
  const [anchorEl, setAnchorEl] = useState(null);

  const [loading, setLoading] = useState(false);


  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  function generateDaysOfWeek() {
    const days = [];
    for (let i = 0; i < 3; i++) {
      const day = new Date(today);
      day.setDate(today.getDate() + i);
      days.push(day);
    }
    return days;
  }

  useEffect(() => {
    const generateTimeSlots = () => {
      const slots = [];
      for (let hour = 11; hour <= 22; hour++) {
        const time = `${hour % 12 === 0 ? 12 : hour % 12}:00 ${hour >= 12 ? 'PM' : 'AM'}`;
        slots.push(time);
      }
      return slots;
    };

    setTimeSlots(generateTimeSlots());
  }, []);

  const handleSelectSkill = (skill) => {
    setSelectedSkill(skill);
    setStep(2);
  };

  const handleSelectGrade = (grade) => {
    setSelectedGrade(grade);
    setStep(3);
  };

  const handleSubmit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;

    if (!childName.trim()) {
      setSnackbarMessage('Please enter your name.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    if (!emailRegex.test(email.trim())) {
      setSnackbarMessage('Please enter a valid email address.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    if (!phoneRegex.test(phoneNumber)) {
      setSnackbarMessage('Please enter a valid 10-digit phone number.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    setStep(4);
  };

  const handleDateSelection = (date) => {
    setSelectedDate(date);
    setStep(5);
  };

  const handleTimeSelection = (time) => {
    setSelectedTimeSlot(time);
    setSnackbarMessage('Your trial has been booked!');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
    setStep(1);
  };

  const handleDateChange = (event, newValue) => {
    setSelectedDate(newValue);
  };

  const handleSlotSelection = (time) => {
    setSelectedTimeSlot(time);
  };

  const formatDate = (date) => {
    if (!date) return '';
    return date.toISOString().split('T')[0]; // Extract YYYY-MM-DD
  };
  
  const formatTime = (time) => {
    // Convert time from 12-hour format to 24-hour format if needed
    if (!time) return '';
    const [hour, minutePeriod] = time.split(':');
    const [minute, period] = [minutePeriod.slice(0, 2), minutePeriod.slice(2)];
  
    let hour24 = parseInt(hour, 10);
    if (period.toUpperCase() === 'PM' && hour24 !== 12) {
      hour24 += 12;
    } else if (period.toUpperCase() === 'AM' && hour24 === 12) {
      hour24 = 0;
    }
  
    return `${hour24.toString().padStart(2, '0')}:${minute}`; // Ensure format HH:MM
  };
  
  const handleSubmitDateTime = async () => {
    setLoading(true)
    // Ensure daysOfWeek contains Date objects
    const formattedDate = formatDate(daysOfWeek[selectedDate]);
    const formattedTime = formatTime(selectedTimeSlot);
  
    const bookingData = {
      skill: selectedSkill,
      grade: selectedGrade,
      email,
      phoneNumber,
      childName,
      countryCode,
      date: formattedDate,
      time: formattedTime,
    };
  
    console.log('Booking Data:', bookingData); // Log data to verify
  
    try {
      await bookTrial(bookingData);
      setSnackbarMessage('Your trial has been booked!');
      setSnackbarSeverity('success');
      setStep(5);
    } catch (error) {
      console.log('Booking Error:', error); // Log detailed error
      setSnackbarMessage('There was an error booking your trial: ' + error.message);
      setSnackbarSeverity('error');


    } finally {
      setSnackbarOpen(true);
      setLoading(false)

    }
  };
  
  
  const isSlotDisabled = (slotTime) => {
    const currentDate = new Date();
    const slotHour = parseInt(slotTime.split(':')[0]);
    const isPM = slotTime.includes('PM');
    const currentHour = currentDate.getHours();

    if (selectedDate !== 0) {
      return false;
    }

    const slot24Hour = (slotHour % 12) + (isPM ? 12 : 0);
    return slot24Hour <= currentHour;
  };

  const handleTimeZoneChange = (zone) => {
    setSelectedTimeZone(zone.value);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleBack = () => {
    if (step === 4) {
      setStep(3); // Go back to Step 3 from Step 4
    } else if (step === 3) {
      setStep(2); // Go back to Step 2 from Step 3
    } else if (step === 2) {
      setStep(1); // Go back to Step 1 from Step 2
    }
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <Box sx={{ textAlign: 'center', px: isMobile ? 2 : 4, py: isMobile ? 2 : 4 }}>
          <Typography variant="h5" gutterBottom>
            STEP 1
          </Typography>
          <Typography variant="h6" sx={{ my: 2 }}>
            Which of the following skills would you like to develop in your child:
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
            <Button
              variant="outlined"
              fullWidth
              sx={{ my: 1 }}
              onClick={() => handleSelectSkill('Personality Enrichment')}
              endIcon={<ArrowForward />}
            >
              Personality Enrichment
            </Button>
            <Tooltip title="Know More" arrow>
            <a href="https://www.oratrics.com/personality-enrichment.php" target="_blank" rel="noopener noreferrer">
            <IconButton
              color="primary"
              sx={{ ml: 2 }} // Margin to separate icon button from the previous button
            >
              
                <Info />
              </IconButton>
              </a>
            </Tooltip>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>

          <Button
            variant="outlined"
            fullWidth
            sx={{ my: 1 }}
            onClick={() => handleSelectSkill('Public Speaking')}
            endIcon={<ArrowForward />}
          >
            Public Speaking
          </Button>
          <Tooltip title="Know More" arrow>
           <a href="https://www.oratrics.com/public-speaking.php" target="_blank" rel="noopener noreferrer">
            <IconButton
              color="primary"
              sx={{ ml: 2 }} // Margin to separate icon button from the previous button
            >
              
                <Info />
              </IconButton>
              </a>
            </Tooltip>
</Box>          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>

          <Button
            variant="outlined"
            fullWidth
            sx={{ my: 1 }}
            onClick={() => handleSelectSkill('Creative Writing')}
            endIcon={<ArrowForward />}
          >
            Creative Writing
          </Button>
          <Tooltip title="Know More" arrow>
           <a href="https://www.oratrics.com/creative-writing.php" target="_blank" rel="noopener noreferrer">
            <IconButton
              color="primary"
              sx={{ ml: 2 }} // Margin to separate icon button from the previous button
            >
              
                <Info />
              </IconButton>
              </a>
            </Tooltip>
</Box>          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>

          <Button
            variant="outlined"
            fullWidth
            sx={{ my: 1 }}
            onClick={() => handleSelectSkill('Confidence Building')}
            endIcon={<ArrowForward />}
          >
            Confidence Building
          </Button>
          <Tooltip title="Know More" arrow>
           <a href="https://www.oratrics.com/confidence-building-social-skill-enhancement-program.php" target="_blank" rel="noopener noreferrer">
            <IconButton
              color="primary"
              sx={{ ml: 2 }} // Margin to separate icon button from the previous button
            >
              
                <Info />
              </IconButton>
              </a>
            </Tooltip>
</Box>          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>

          <Button
            variant="outlined"
            fullWidth
            sx={{ my: 1 }}
            onClick={() => handleSelectSkill('Leadership Program')}
            endIcon={<ArrowForward />}
          >
            Leadership Program
          </Button>
          <Tooltip title="Know More" arrow>
           <a href="https://www.oratrics.com/leadership-program.php" target="_blank" rel="noopener noreferrer">
            <IconButton
              color="primary"
              sx={{ ml: 2 }} // Margin to separate icon button from the previous button
            >
              
                <Info />
              </IconButton>
              </a>
            </Tooltip>
          </Box>
        </Box>
        );
      case 2:
        return (
          <Box sx={{ textAlign: 'center', px: isMobile ? 2 : 4, py: isMobile ? 2 : 4 }}>
            <Typography variant="h5">STEP 2</Typography>
            <Typography variant="h6" sx={{ my: 2 }}>
              Please select your child's grade:
            </Typography>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))', gap: 1 }}>
              {['1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th', '11th', '12th'].map((grade, index) => (
                <Button key={index} variant="outlined" fullWidth onClick={() => handleSelectGrade(grade)}>
                  {grade}
                </Button>
              ))}
            </Box>
            <IconButton sx={{mt:5}} onClick={() =>  setStep(1 )}>
                    <ArrowBackIcon />
                  </IconButton>
          </Box>
        );
      case 3:
        return (
          <Box sx={{ textAlign: 'center', px: isMobile ? 2 : 4, py: isMobile ? 0 : 4 }}>
            <Typography variant="h5">STEP 3</Typography>
            <Typography variant="h6" sx={{ my: 2 }}>
              Please enter your details:
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter your child's name"
              value={childName}
              onChange={(e) => setChildName(e.target.value)}
              sx={{ my: 1 }}
            />
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ my: 1 }}
            />
            <Grid container spacing={2}>
              <Grid item xs={3} sm={3}>
                <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                  <InputLabel>Country Code</InputLabel>
                  <Select
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                    label="Country Code"
                  >
                    {countryCodes.map(({ code, country }) => (
                      <MenuItem key={code} value={code}>
                        {country} ({code})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={9} sm={9}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Enter your phone number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  sx={{ my: 1 }}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  }}>

         
<IconButton                     sx={{ marginRight:10 }}
 onClick={() =>  setStep(2)}>
                    <ArrowBackIcon />
                  </IconButton>
            <Button variant="contained" color="primary"  sx={{ marginLeft:2}} onClick={handleSubmit}>
              Next
            </Button>
            </Box>
          </Box>
        );
      case 4:
        return (
          <Container>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" sx={{ mr: 2 }}>
                  Select your time zone:
                </Typography>
                <TimezoneSelect
                  value={{ value: selectedTimeZone, label: selectedTimeZone }}
                  onChange={handleTimeZoneChange}
                  clearable={false}
                  style={{ minWidth: 250 }}
                />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
              {/* {!isMobile && (
                  <IconButton onClick={handleBack}>
                    <ArrowBackIcon />
                  </IconButton>
                )} */}
                <AppBar position="static" color="default" sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1  }}>
                  <Tabs
                    value={selectedDate}
                    onChange={handleDateChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{ overflowX: 'auto' }}
                  >
                    {daysOfWeek.map((day, index) => (
                      <Tab
                        key={index}
                        label={day.toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric' })}
                        sx={{ minWidth: '100px' }}
                      />
                    ))}
                  </Tabs>
                </AppBar>
                  {/* {!isMobile && (
                    <IconButton onClick={() => setSelectedDate(prev => Math.min(prev + 1, daysOfWeek.length - 1))} disabled={selectedDate === daysOfWeek.length - 1}>
                      <ArrowForwardIcon />
                    </IconButton>
                  )} */}
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1} justifyContent="center">
                  {timeSlots.map((time, index) => (
                    <Grid item key={index} xs={4} sm={4} md={3}>
                      <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        sx={{
                          my: 1,
                          backgroundColor: selectedTimeSlot === time ? theme.palette.primary.main : 'transparent',
                          color: selectedTimeSlot === time ? theme.palette.primary.contrastText : 'inherit',
                          borderColor: selectedTimeSlot === time ? theme.palette.primary.main : 'inherit',
                          '&:hover': {
                            backgroundColor: selectedTimeSlot === time ? theme.palette.primary.dark : 'inherit',
                          },
                          opacity: isSlotDisabled(time) ? 0.5 : 1,
                        }}
                        onClick={() => handleSlotSelection(time)}
                        disabled={isSlotDisabled(time)}
                      >
                        {time}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt:3 }}>

         
<IconButton                    sx={{ marginRight:10 }}
 onClick={() =>  setStep(3)}>
                    <ArrowBackIcon />
                  </IconButton>
              {selectedTimeSlot && (
               
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmitDateTime}
                    disabled={loading} // Disable button when loading
      endIcon={loading ? <CircularProgress size={24} color="inherit" /> : null} // Show spinner while loading
    >
      {loading ? 'Submitting...' : 'Submit'}
      </Button>
          
              )}
              </Box>
            </Box>
          </Container>
        );
      case 5:
        return <Step5 />; // Render the new Step5 component
      default:
        return null;
    }
  };
  return (
    <>
    {/* AppBar Header */}
    <AppBar position="static" sx={{ boxShadow: 0.5, backgroundColor: '#fff' }}>
  <Toolbar
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingX: isMobile ? 2 : 4,
      height: '80px',
    }}
  >
    <Link href="/" underline="none">
      <img src={logo} alt="Logo" style={{ height: 50 }} />
    </Link>
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Link 
        href="https://www.oratrics.com" 
        target="_blank" 
        rel="noopener noreferrer" 
        sx={{
          textDecoration: 'none',
          color: '#050a30',
          fontWeight: 'bold',
          fontSize: '1.1rem',
          padding: '8px 16px',
          borderRadius: '4px',
          backgroundColor: '#f0f0f0',
          '&:hover': {
            backgroundColor: '#e0e0e0',
            color: '#333',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
          },
        }}
      >
        About Us
      </Link>
    </Box>
  </Toolbar>
</AppBar>

    {/* Main Content */}
    <Box
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: `calc(100vh - 80px)`, // Adjust height to account for the fixed AppBar
        pt: 2, // Padding-top for spacing
        overflowY: 'auto',
        paddingX: isMobile ? 2 : 4, // Horizontal padding
      }}
    >
      {/* Portrait Image */}
      {step !== 5 && (
        <Box
          sx={{
            flex: isMobile ? 'none' : '40%',
            width: isMobile ? '100%' : '60%',
            height: isMobile ? 'auto' : 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            // mb: isMobile ? 0 : 0, // Margin-bottom for spacing on mobile
          }}
        >
          <img
            src={portrait}
            alt="Skill Development"
            style={{
              width: '80%',
              height: isMobile ? '100%' : '100%',
              objectFit: 'cover',
              borderRadius: 4,
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: isMobile ? -35 : -50,
              left: '50%',
              transform: 'translateX(-50%)',
              width: '100%', // Reduced width for mobile
              textAlign: 'center',
              p: isMobile ? 1 : 2,
              backgroundColor: '#050a30',
              color: 'white',
              borderBottom: '4px solid #8b0000',
            }}
          >
            <Typography
              variant={isMobile ? 'h6' : 'h5'}
              sx={{
                fontWeight: 'bold',
                fontFamily: 'Georgia, serif',
                textTransform: 'uppercase',
                fontSize: isMobile ? '1rem' : '1.5rem',
              }}
            >
              Make your kids the best version of themselves
            </Typography>
          </Box>
        </Box>
      )}

      {/* Step Content */}
      <Box
        sx={{
          flex: isMobile ? 'none' : '30%',
          width: isMobile ? '90%' : '40%',
          textAlign: 'center',
          px: 2,
          py: isMobile ? 2 : 4,
        }}
      >
        {renderStepContent()}
      </Box>
    </Box>

    {/* Snackbar for Alerts */}
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
      sx={{ bottom: 20 }} // Adjusted position from the bottom
    >
      <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
        {snackbarMessage}
      </MuiAlert>
    </Snackbar>
  </>
  );
}

export default App;
